import "../../App.css";
import { useEffect, useState } from "react";
import { Dropdown, Menu, Modal, Tooltip } from "antd";
import {
  deleteTask,
  duplicateTask,
  softDelete,
  updateTask,
  updateTaskOrder,
} from "../../redux/tasksSlice";
import {
  setCardModalActive,
  setToastVisible,
  updateCurrentUser,
} from "../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import linkifyHtml from "linkify-html";
import "./CardModal.css";

import TextareaAutosize from "react-textarea-autosize";

import DurationPicker from "../Kanban/Card/Components/DurationPicker";
import CompleteInput from "../Kanban/Card/Components/CompleteInput";
import LabelSelector from "../Kanban/Card/Components/LabelSelector";
import DatePickerDropdown from "../Sidebar/DatePicker/DatePickerDropdown";
import Subtasks from "../Kanban/Card/Components/Subtasks";

import _ from "lodash";
import moment from "moment-timezone";
import { FiExternalLink, FiMoreVertical, FiX } from "react-icons/fi";

import styled from "@emotion/styled";
import {
  TbCalendar,
  TbCalendarDown,
  TbCalendarPlus,
  TbCalendarShare,
  TbExternalLink,
  TbFlag,
  TbFolder,
  TbFolderShare,
  TbLink,
  TbRepeat,
  TbTargetArrow,
} from "react-icons/tb";
import { RiFlag2Fill, RiFlag2Line, RiTimeLine } from "react-icons/ri";

import RecurringInput from "../Kanban/Card/Components/RecurringInput";
import {
  MdArrowDropDownCircle,
  MdOutlineArrowDropDownCircle,
} from "react-icons/md";
import { TiArrowRepeat } from "react-icons/ti";
import { useHotkeys } from "react-hotkeys-hook";
import {
  deleteAllRecurringInstances,
  updateIncompleteInstances,
} from "../../redux/recurringTasksSlice";
import { PlayIcon } from "@heroicons/react/24/solid";
import AutoGrowingTextAreaWithLinks from "./AutoGrowingTextAreaWithLinks";
import { FcGoogle } from "react-icons/fc";
import GoogleCalendarEventDetail from "./GoogleCalendarEventDetail";
import {
  analytics,
  calculateDaysToDueDateText,
  getClickupLinkForTask,
  getCraftDocsLinkForTask,
  getFigmaLinkForTask,
  getGoogleDocsLinkForTask,
  getGoogleDriveLinkForTask,
  getIndicatorColorForDueDate,
  getLinearLinkForTask,
  getNotionLinkForTask,
  getSlackLinkForTask,
  isDev,
  tasksServerUrl,
} from "../../utils";
import axios from "axios";
import ListPickerDropdown from "../Sidebar/Lists/ListPicker";
import { toast } from "sonner";
import { FaRegFlag } from "react-icons/fa6";
import PrioritySelector from "../Generics/PrioritySelector";
import { PiSlackLogo } from "react-icons/pi";
import { RiNotionFill } from "react-icons/ri";
import { FaFigma } from "react-icons/fa";
import { SiGoogledocs, SiLinear } from "react-icons/si";
import TaskNotificationSettingDropdown from "./TaskNotificationSettingDropdown";
import { IoMdAttach } from "react-icons/io";
import AttachmentSelector from "../Generics/Attachments/AttachmentSelector";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import ActiveTimerIndicator from "../Kanban/Card/Components/TimeSection/ActiveTimerIndicator";

export default function CardModal(labelManager) {
  const { cardModalActiveFor } = useSelector((state) => state.app);
  const {
    active_timer = null,
    slotMinTime = "00:00",
    rich_links = {},
  } = useSelector((state) => state.app.currentUser);

  const userId = useSelector((state) => state.app.uid);
  const active_timer_task = useSelector(
    (state) => state.tasks?.data[active_timer?.task_id]
  );

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
      }
  );

  const priority_settings_enabled = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.priority_enabled || false
  );

  const activeTimerAccumulatedTime = useSelector(
    (state) => state.app.activeTimerAccumulatedTime
  );

  const attachmentsEnabled = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.attachments_enabled || false
  );

  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const dispatch = useDispatch();

  const item = useSelector(
    (state) => state.tasks.data[state.app.cardModalActiveFor]
  );

  const [taskEditable, setTaskEditable] = useState(item);

  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [dueDatePickerVisible, setDueDatePickerVisible] = useState(false);
  const [listPickerVisible, setListPickerVisible] = useState(false);
  const [prioritySelectorVisible, setPrioritySelectorVisible] = useState(false);
  const [googleDocLink, setGoogleDocLink] = useState(null);
  const [notionLink, setNotionLink] = useState(null);
  const [figmaLink, setFigmaLink] = useState(null);
  const [slackLink, setSlackLink] = useState(null);

  const [estimatedTimeActive, setEstimatedTimeActive] = useState(false);
  const [actualTimeActive, setActualTimeActive] = useState(false);

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);

  const [taskNotificationSettingVisible, setTaskNotificationSettingVisible] =
    useState(false);

  const [prioritySelectorActive, setPrioritySelectorActive] = useState(false);

  const taskOrder = useSelector((state) => state.tasks.order);

  const recurringTask = useSelector(
    (state) => state.tasks.recurringTasks[taskEditable?.recurring_id]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();
      dispatch(setCardModalActive(null));
    },
    {
      enabled: cardModalActiveFor !== null && taskEditable !== null,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [cardModalActiveFor, taskEditable]
  );

  useHotkeys(
    "cmd+l, ctrl+l",
    (e) => {
      e.preventDefault();

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: cardModalActiveFor !== null && taskEditable !== null,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [cardModalActiveFor, taskEditable]
  );

  useHotkeys(
    "cmd+e, ctrl+e",
    (e) => {
      e.preventDefault();

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        setEstimatedTimeActive(true);
      }

      if (actualTimeActive) {
        setActualTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: cardModalActiveFor !== null && taskEditable !== null,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [cardModalActiveFor, taskEditable, estimatedTimeActive, actualTimeActive]
  );

  useHotkeys(
    "cmd+b, ctrl+b",
    (e) => {
      e.preventDefault();

      if (actualTimeActive) {
        setActualTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        setActualTimeActive(true);
      }

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: cardModalActiveFor !== null && taskEditable !== null,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [cardModalActiveFor, taskEditable, actualTimeActive, estimatedTimeActive]
  );

  async function updateOrder(date, order) {
    dispatch(
      updateTaskOrder({
        date: date,
        order: order,
        previousOrder: taskOrder[date]?.order,
      })
    );
  }

  async function updateItemEditableValue(key, value, save = false) {
    if (key === "date") {
      // If key is "date", we need to update the task order as well
      var oldDate = taskEditable?.date
        ? moment(taskEditable?.date).format("YYYY-MM-DD")
        : null;
      var newDate = moment(value).format("YYYY-MM-DD");

      if (oldDate !== newDate) {
        // Let's deal with the removal first

        var orderToRemove = _.cloneDeep(
          taskOrder[oldDate || taskEditable?.listId || "brain_dump"]
        );

        // TODO: NEED TO ACCOUNT FOR UPDATING ORDER IF WE HAVE A NON LOADED DATE
        const updatedOrderToRemove = orderToRemove.order
          ? orderToRemove.order.filter((task) => task !== taskEditable?.id)
          : [];

        updateOrder(oldDate || "brain_dump", updatedOrderToRemove);

        // If not null, add it to the relevant date
        var orderToAddTask = _.cloneDeep(
          taskOrder[newDate || taskEditable?.listId || "brain_dump"]
        );

        var updatedNewOrder = [];

        if (orderToAddTask && orderToAddTask.order) {
          updatedNewOrder = orderToAddTask.order;
          updatedNewOrder.unshift(taskEditable?.id);
        } else {
          updatedNewOrder = [taskEditable?.id];
        }

        updateOrder(
          newDate || taskEditable?.listId || "brain_dump",
          updatedNewOrder
        );
      }
      dispatch(
        updateTask({
          taskId: taskEditable?.id,
          currentTask: taskEditable,
          newData: {
            date: value,
            start: value,
          },
        })
      );
    } else {
      dispatch(
        updateTask({
          taskId: taskEditable?.id,
          currentTask: taskEditable,
          newData: {
            [key]: value,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (item) {
      setTaskEditable(item);
    } else {
      setTaskEditable(null);
      // Close
      dispatch(setCardModalActive(null));
    }
  }, [item]);

  function moveTaskToDate(newDate, isList) {
    var systemTimezone = moment.tz.guess();

    // If the systemTimezone is null, default to Central Time
    if (!systemTimezone) {
      systemTimezone = "America/Chicago";
    }

    // If there is a start time and this isn't a list, set to true
    const shouldUpdateStart = taskEditable?.start && !isList;

    return axios
      .post(
        `${tasksServerUrl}/moveTaskToSpecificDate`,
        {
          taskData: { ...taskEditable, date: item?.date || null },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            systemTimezone: systemTimezone,
            taskId: taskEditable?.id,
            uid: userId,
            newDateString: newDate,
            shouldUpdateStart: shouldUpdateStart,
          },
        }
      )
      .then((response) => {
        function getTimeString(date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();

          // Padding single digit numbers with a leading zero
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;

          return `${hours}:${minutes}`;
        }

        const newTime = getTimeString(new Date(newDate));

        if (newTime < slotMinTime) {
          toast.message("Task is out of timebox range 🔍", {
            description:
              "Your task was updated but it's outside the timebox range. You can change this in your settings.",
          });
        }
        setTaskEditable({
          ...taskEditable,
          date: isList ? null : new Date(newDate),
          listId: isList ? newDate : null,
        });

        if (isList) {
          setListPickerVisible(false);
        } else {
          setDatePickerVisible(false);
        }
      })
      .catch((error) => {
        console.log("Error changing date :", error);
        dispatch(
          setToastVisible({
            toastType: "error",
            message:
              "Something went wrong changing date. Please contact support",
          })
        );
      });
  }

  const renderEmailBody = () => {
    if (taskEditable?.email_info?.HtmlBody) {
      const linkifiedContent = linkifyHtml(taskEditable.email_info.HtmlBody, {
        defaultProtocol: "https",
        target: "_blank",
        attributes: {
          style: "color: blue;", // This sets the link color to blue
        },
      });
      return parse(linkifiedContent);
    }
    return null;
  };

  const menu = (
    <Menu>
      {recurringTask && (
        <Menu.Item
          key="1"
          onClick={() => {
            dispatch(
              updateIncompleteInstances({
                recurringTask: recurringTask,
                taskToMatch: taskEditable,
              })
            );
          }}
        >
          <span>Update all incomplete tasks to match this task</span>
        </Menu.Item>
      )}
      <Menu.Item
        key="3"
        onClick={() => {
          dispatch(setCardModalActive(null));

          dispatch(
            duplicateTask({
              taskId: taskEditable?.id,
              taskToDuplicate: taskEditable,
            })
          );
        }}
      >
        <span>Duplicate task</span>
      </Menu.Item>
      <Menu.Item
        key="0"
        onClick={() => {
          dispatch(setCardModalActive(null));
          dispatch(softDelete(item));
        }}
      >
        <span>Delete {recurringTask && "this task"}</span>
      </Menu.Item>
      {recurringTask && (
        <Menu.Item
          key="2"
          onClick={() => {
            dispatch(setCardModalActive(null));

            dispatch(
              deleteAllRecurringInstances({
                recurringTask: recurringTask,
              })
            );
          }}
        >
          <span>Delete all instances</span>
        </Menu.Item>
      )}
      {isDev && (
        <Menu.Item
          key="3"
          onClick={() => {
            navigator.clipboard.writeText(item.id);
          }}
        >
          <span> Copy ID</span>
        </Menu.Item>
      )}
    </Menu>
  );

  function countLinks(googleDocLink, notionLink, figmaLink, slackLink) {
    let count = 0;
    if (googleDocLink) count++;
    if (notionLink) count++;
    if (figmaLink) count++;
    if (slackLink) count++;
    return count;
  }

  const linkCount = countLinks(googleDocLink, notionLink, figmaLink, slackLink);

  function enterStopwatchMode() {
    if (active_timer_task) {
      dispatch(
        updateTask({
          taskId: active_timer_task.id,
          currentTask: active_timer_task,
          newData: {
            actual_time:
              (active_timer_task.actual_time || 0) + activeTimerAccumulatedTime,
          },
        })
      );
    } else {
      // Lets update actual_time just in case to materialize the task if its recurring
      if (taskEditable.recurring) {
        dispatch(
          updateTask({
            taskId: taskEditable.id,
            currentTask: item,
            newData: {
              actual_time: taskEditable.actual_time || 0,
            },
          })
        );
      }
    }

    const active_timer = {
      task_id: taskEditable?.id,
      active: true,
      focus_time_original: 0,
      focus_time_left: 0,
      last_start_time: new Date(),
      time_accumulated: 0,
      type: "stopwatch",
    };

    analytics("Focus mode entered", {
      start_duration: active_timer.focus_time_original,
      task_id: taskEditable?.id,
      type: "stopwatch",
    });

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: active_timer },
        previousValues: { active_timer: null },
      })
    );
    dispatch(setCardModalActive(null));
  }

  // Keyboard shortcut to start timer
  useHotkeys(
    "cmd+shift+enter, ctrl+shift+enter",
    (e) => {
      e.preventDefault();

      // If this task is already running, do nothing
      if (active_timer_task) {
        return;
      } else {
        enterStopwatchMode();
      }
    },
    {
      enabled: cardModalActiveFor !== null && taskEditable !== null,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [cardModalActiveFor, taskEditable, active_timer_task]
  );

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={600}
      open={cardModalActiveFor !== null && taskEditable !== null}
      onOk={() => {
        dispatch(setCardModalActive(null));
      }}
      onCancel={() => {
        dispatch(setCardModalActive(null));
      }}
      className="amazing-modal"
      destroyOnClose={true}
    >
      <div className="create-task-modal">
        <ActiveTimerIndicator item={taskEditable} mode="modal" />
        <div className="card-modal-header editing">
          <CompleteInput
            item={taskEditable}
            complete={taskEditable?.complete}
            style={{
              width: "20px",
              height: "20px",
              flex: "0 0 20px",
              marginTop: "13px",
              borderRadius: "5px",
            }}
            noCheckbox={true}
          />

          <TextareaAutosize
            onBlur={() => {
              dispatch(
                updateTask({
                  taskId: taskEditable?.id,
                  currentTask: item,
                  newData: {
                    description: taskEditable?.description || "",
                  },
                })
              );
            }}
            placeholder="Enter a description"
            className="card-modal-description ph-mask"
            value={taskEditable?.description}
            onChange={(e) => {
              setTaskEditable({
                ...taskEditable,
                description: e.target.value,
              });
            }}
            onKeyPress={(e) => {
              // Detect if enter is pressed and save the task
              if (e.key === "Enter") {
                e.preventDefault();
                dispatch(
                  updateTask({
                    taskId: taskEditable?.id,
                    currentTask: item,
                    newData: {
                      description: taskEditable?.description || "",
                    },
                  })
                );
                dispatch(setCardModalActive(null));
              }
            }}
            style={{}}
          />

          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="more-icon-container">
              <FiMoreVertical />
            </div>
          </Dropdown>
        </div>

        <div className="card-modal-body">
          <div className="modal-card-properties">
            {taskEditable && taskEditable?.date && (
              <div className="modal-property">
                <div className="title">
                  <TbCalendar className="icon" />
                  Task date
                </div>
                <div className="value">
                  {taskEditable && (
                    <div className="flex flex-row items-center gap-1">
                      <DatePickerDropdown
                        dateSelected={taskEditable?.date || null}
                        isVisible={datePickerVisible}
                        setIsVisible={(newValue) => {
                          // If it is being set to false, then we need to save the date
                          if (taskEditable?.date && !newValue) {
                            const dateString = taskEditable?.date.toISOString();

                            moveTaskToDate(dateString, false);
                          }
                          setDatePickerVisible(newValue);
                        }}
                        onDateChange={(date) => {
                          // Check if the time is different, if so, update the start time to match
                          const dateToCheck = new Date(date);
                          const taskDate = taskEditable?.date
                            ? new Date(
                                taskEditable?.date.toDate
                                  ? taskEditable?.date.toDate()
                                  : taskEditable?.date
                              )
                            : null;

                          // If there is no task date, or the task date is different, then we need to update the start time
                          if (
                            taskDate &&
                            (dateToCheck.getHours() !== taskDate.getHours() ||
                              dateToCheck.getMinutes() !==
                                taskDate.getMinutes())
                          ) {
                            setTaskEditable({
                              ...taskEditable,
                              date: date,
                              start: date,
                            });
                          } else {
                            // if there is no task date to begin AND the new one is not midnight, then we need to update the start time
                            if (!taskDate && dateToCheck.getHours() !== 0) {
                              setTaskEditable({
                                ...taskEditable,
                                date: date,
                                start: date,
                              });
                            } else {
                              setTaskEditable({
                                ...taskEditable,
                                date: date,
                              });
                            }
                          }

                          //  setDatePickerVisible(false);
                          // moveTaskToDate(dateString);
                        }}
                      />
                      <ListPickerDropdown
                        listSelected={
                          taskEditable?.listId ||
                          (!taskEditable?.date ? "brain_dump" : null)
                        }
                        isVisible={listPickerVisible}
                        setIsVisible={(newValue) => {
                          // If it is being set to false, then we need to save the date

                          setListPickerVisible(newValue);
                        }}
                        onListChange={(listId) => {
                          // To ISO String
                          moveTaskToDate(listId, true);

                          setTaskEditable({
                            ...taskEditable,
                            listId: listId,
                            date: null,
                          });
                          setListPickerVisible(false);
                        }}
                      >
                        <div
                          onClick={() => {
                            //   enterStopwatchMode();
                          }}
                          className="text-neutral-400 text-xs flex flex-row items-center gap-1 cursor-pointer hover:text-neutral-800 dark:hover:text-neutral-200"
                        >
                          (Move this task to a List)
                        </div>
                      </ListPickerDropdown>
                    </div>
                  )}
                </div>
              </div>
            )}
            {due_date_settings.enabled && (
              <div className="modal-property">
                <div className="title">
                  <TbTargetArrow className="icon" />
                  Due date
                </div>
                <div className="value">
                  <DatePickerDropdown
                    dateSelected={taskEditable?.due_date}
                    isVisible={dueDatePickerVisible}
                    setIsVisible={setDueDatePickerVisible}
                    onDateChange={(date) => {
                      updateItemEditableValue("due_date", date, true);
                    }}
                    timeDisabled={true}
                    isDueDate={true}
                    complete={taskEditable?.complete}
                  />
                  {taskEditable?.due_date && (
                    <TaskNotificationSettingDropdown
                      isVisible={taskNotificationSettingVisible}
                      setIsVisible={setTaskNotificationSettingVisible}
                      due_date_notification_meta={
                        taskEditable?.due_date_notification_meta
                      }
                      due_date_notification_time={
                        taskEditable?.due_date_notification_time
                      }
                      due_date_notification_sent={
                        taskEditable?.due_date_notification_sent
                      }
                      due_date={
                        taskEditable?.due_date?.toDate
                          ? taskEditable?.due_date.toDate()
                          : taskEditable?.due_date
                      }
                      updateTaskWithNotificationSettings={(value) => {
                        console.log(value);
                        dispatch(
                          updateTask({
                            taskId: taskEditable?.id,
                            currentTask: item,
                            newData: value,
                          })
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            )}

            {taskEditable && (taskEditable?.listId || !taskEditable?.date) && (
              <div className="modal-property">
                <div className="title">
                  <TbFolder className="icon" />
                  List
                </div>
                <div className="value">
                  {taskEditable && (
                    <div className="flex flex-row items-center gap-1">
                      <ListPickerDropdown
                        listSelected={
                          taskEditable?.listId ||
                          (!taskEditable?.date ? "brain_dump" : null)
                        }
                        isVisible={listPickerVisible}
                        setIsVisible={(newValue) => {
                          // If it is being set to false, then we need to save the date

                          setListPickerVisible(newValue);
                        }}
                        onListChange={(listId) => {
                          // To ISO String
                          moveTaskToDate(listId, true);

                          setTaskEditable({
                            ...taskEditable,
                            listId: listId,
                          });
                          setListPickerVisible(false);
                        }}
                      />
                      <DatePickerDropdown
                        dateSelected={taskEditable?.date || null}
                        isVisible={datePickerVisible}
                        setIsVisible={(newValue) => {
                          // If it is being set to false, then we need to save the date
                          if (taskEditable?.date && !newValue) {
                            const dateString = taskEditable?.date.toISOString();

                            moveTaskToDate(dateString, false);
                          }
                          setDatePickerVisible(newValue);
                        }}
                        onDateChange={(date) => {
                          // To ISO String

                          setTaskEditable({
                            ...taskEditable,
                            date: date,
                            listId: null,
                          });
                          //  setDatePickerVisible(false);
                          // moveTaskToDate(dateString);
                        }}
                      >
                        <div
                          onClick={() => {
                            //   enterStopwatchMode();
                          }}
                          className="text-neutral-400 text-xs flex flex-row items-center gap-1 cursor-pointer hover:text-neutral-800 dark:hover:text-neutral-200"
                        >
                          (Move this task to a Date)
                        </div>
                      </DatePickerDropdown>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Estimated time
              </div>
              <div className="value">
                {taskEditable && (
                  <DurationPicker
                    time={
                      !isNaN(taskEditable?.estimated_time)
                        ? taskEditable?.estimated_time
                        : null
                    }
                    item={taskEditable}
                    title="Estimated"
                    updateTimeLocal={(newValue) => {
                      updateItemEditableValue("estimated_time", newValue, true);
                    }}
                    buttonMode={true}
                    durationPickerActive={estimatedTimeActive}
                    setDurationPickerActive={setEstimatedTimeActive}
                  />
                )}
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Actual time
              </div>
              <div className="value">
                {taskEditable && (
                  <DurationPicker
                    time={
                      !isNaN(taskEditable?.actual_time)
                        ? taskEditable?.actual_time
                        : null
                    }
                    item={taskEditable}
                    title="Actual"
                    updateTimeLocal={(newValue) => {
                      updateItemEditableValue("actual_time", newValue, true);
                    }}
                    buttonMode={true}
                    durationPickerActive={actualTimeActive}
                    setDurationPickerActive={setActualTimeActive}
                  />
                )}
                {active_timer &&
                  active_timer_task &&
                  active_timer?.task_id == taskEditable?.id && (
                    <Tooltip
                      title="Timer already active, check the bottom right of your screen"
                      placement="top"
                      enabled={false}
                    >
                      <div
                        onClick={() => {
                          //   enterStopwatchMode();
                        }}
                        className="text-neutral-400 text-xs flex flex-row items-center gap-1 cursor-pointer hover:text-neutral-800 dark:hover:text-neutral-200"
                        style={{
                          cursor: "not-allowed",
                          opacity: 0.5,
                        }}
                      >
                        (Start timer)
                      </div>
                    </Tooltip>
                  )}

                {(active_timer == null ||
                  active_timer_task == null ||
                  active_timer?.task_id != taskEditable?.id) && (
                  <div
                    onClick={() => {
                      enterStopwatchMode();
                    }}
                    className="text-neutral-400 text-xs flex flex-row items-center gap-1 cursor-pointer hover:text-neutral-800 dark:hover:text-neutral-200"
                  >
                    {" "}
                    (Start timer)
                  </div>
                )}
              </div>
            </div>

            {priority_settings_enabled && (
              <div className="modal-property">
                <div className="title">
                  <TbFlag className="icon" />
                  Priority
                </div>
                <div className="value">
                  {taskEditable && (
                    <PrioritySelector
                      priority={taskEditable?.priority}
                      prioritySelectorActive={prioritySelectorActive}
                      item={taskEditable}
                      modal={true}
                      setPrioritySelectorVisible={setPrioritySelectorVisible}
                      prioritySelectorVisible={prioritySelectorVisible}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="modal-property">
              <div className="title">
                <MdOutlineArrowDropDownCircle className="icon" />
                Label
              </div>
              <div className="value">
                {taskEditable && (
                  <LabelSelector
                    label={taskEditable?.label}
                    item={taskEditable}
                    labelPickerActive={labelPickerActive}
                    setLabelPickerActive={setLabelPickerActive}
                    buttonMode={true}
                  />
                )}
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <TiArrowRepeat className="icon" />
                Repeats
              </div>
              <div className="value">
                {taskEditable && (
                  <RecurringInput
                    item={taskEditable}
                    recurringTaskMenuOpen={recurringTaskMenuOpen}
                    setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
                    modalMode={true}
                  />
                )}
              </div>
            </div>

            {attachmentsEnabled && (
              <div className={`modal-property items-start !important`}>
                <div className={`title pt-2`}>
                  <IoMdAttach className="icon" />
                  Attachments
                </div>
                <div className="value">
                  {taskEditable && (
                    <AttachmentSelector
                      attachments={item?.attachments}
                      item={item}
                      userId={userId}
                    />
                  )}
                </div>
              </div>
            )}

            {!rich_links["slack"]?.disabled &&
              getSlackLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Slack
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getSlackLinkForTask(
                          taskEditable,
                          rich_links["slack"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/Slack-mark-RGB.png")}
                        alt="Slack Logo"
                        className="w-3.5 h-3.5 object-contain"
                      />
                      Open in Slack
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["notion"]?.disabled &&
              getNotionLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Notion
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getNotionLinkForTask(
                          taskEditable,
                          rich_links["notion"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/notion-logo-no-background.png")}
                        alt="Notion Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Notion
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["linear"]?.disabled &&
              getLinearLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Linear
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getLinearLinkForTask(
                          taskEditable,
                          rich_links["linear"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/linear-logo.png")}
                        alt="Linear Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Linear
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["figma"]?.disabled &&
              getFigmaLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Figma
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getFigmaLinkForTask(
                          taskEditable,
                          rich_links["figma"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/figma-logo.png")}
                        alt="Figma Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Figma
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["craft"]?.disabled &&
              getCraftDocsLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Craft
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getCraftDocsLinkForTask(
                          taskEditable,
                          rich_links["craft"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/craft-logo.png")}
                        alt="Craft Logo"
                        className="w-5 h-5 object-contain"
                      />
                      Open in Craft
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["googleDocs"]?.disabled &&
              getGoogleDocsLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    Google Docs
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getGoogleDocsLinkForTask(
                          taskEditable,
                          rich_links["googleDocs"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/google-docs-logo.png")}
                        className="w-5 h-5 object-cover"
                        alt="Google Docs Logo"
                      />
                      Open in Google Docs
                    </div>
                  </div>
                </div>
              )}

            {!rich_links["clickUp"]?.disabled &&
              getClickupLinkForTask(taskEditable) && (
                <div className="modal-property">
                  <div className="title">
                    <TbExternalLink className="icon" />
                    ClickUp
                  </div>
                  <div className="value">
                    <div
                      className="mcf-button flex flex-row gap-2 items-center"
                      onClick={() => {
                        const link = getClickupLinkForTask(
                          taskEditable,
                          rich_links["clickUp"]?.deeplink_disabled
                        );
                        window.open(link, "_blank");
                      }}
                    >
                      <img
                        src={require("../../images/clickup-logo.png")}
                        className="w-5 h-5 object-contain"
                        alt="ClickUp Logo"
                      />
                      Open in ClickUp
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div className="modal-card-body-notes">
            <div className="modal-card-body-notes-header">Notes</div>
            <AutoGrowingTextAreaWithLinks
              placeholder="Add any notes to the task"
              value={taskEditable?.notes || ""}
              setValue={(newValue) => {
                setTaskEditable({
                  ...taskEditable,
                  notes: newValue,
                });
              }}
              onBlur={() => {
                dispatch(
                  updateTask({
                    taskId: item?.id,
                    currentTask: item,
                    newData: {
                      notes: taskEditable?.notes || "",
                    },
                  })
                );
              }}
              setNotionLink={setNotionLink}
              setFigmaLink={setFigmaLink}
              setGoogleDocLink={setGoogleDocLink}
              setSlackLink={setSlackLink}
            />
            {/* <TextareaAutosize
              onBlur={() => {
                dispatch(
                  updateTask({
                    taskId: item?.id,
                    currentTask: item,
                    newData: {
                      notes: notesEditable,
                    },
                  })
                );
              }}
              placeholder="Add any notes to the task"
              className="card-modal-notes"
              value={notesEditable}
              onChange={(e) => {
                setNotesEditable(e.target.value);
              }}
              style={{}}
            /> */}
          </div>
          {taskEditable?.email_info?.HtmlBody && (
            <div className="modal-card-body-notes">
              <div className="modal-card-body-notes-header">Email</div>
              <div
                className="card-modal-notes ph-mask !w-full"
                style={{ overflow: "auto", maxHeight: "500px" }}
              >
                {renderEmailBody()}
              </div>
            </div>
          )}

          {taskEditable?.calendar_events &&
            Object.values(taskEditable?.calendar_events)?.length > 0 && (
              <div className="modal-card-body-calendar-events">
                <div className="modal-card-body-notes-header">
                  Calendar event
                </div>
                <GoogleCalendarEventDetail
                  taskCalendarEvent={
                    Object.values(taskEditable?.calendar_events)[0]
                  }
                />
              </div>
            )}

          <div
            className="modal-card-body-notes subtasks"
            style={{
              paddingBottom: "0px",
              borderTop: "none",
            }}
          >
            <div
              className="modal-card-body-notes-header"
              style={{ marginBottom: "0px" }}
            >
              Subtasks
            </div>
            {taskEditable && (
              <Subtasks
                item={taskEditable}
                activelySelected={true}
                subtasksActive={true}
                setSubtasksActive={null}
                modalMode={true}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

const ModalWrapper = styled.div`
  .ant-modal-body {
    padding: 15px important!;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
`;
