import React, { useEffect, useRef, useState } from "react";

import { useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export default function Droppable({
  children,
  id,
  braindump = false,
  mini = false,
  noPadding = false,
  isCollapsed = false, // only applies to mini list
  activelyDragging = false, // only applies to mini list
  listsBeingReordered = false, // only applies to mini list
}) {
  const { setNodeRef, isOver } = useDroppable({
    id: id,
  });

  return (
    <div
      className={`draggable-container ${braindump || mini ? "braindump" : ""} ${
        noPadding ? "!pb-0" : ""
      } ${
        isCollapsed && isOver && !activelyDragging && !listsBeingReordered
          ? "bg-neutral-200 dark:bg-neutral-800 rounded-lg"
          : ""
      }`}
      ref={setNodeRef}
      style={{
        width: "100%",
        transition: "background-color 0.3s ease",
      }}
    >
      {children}
    </div>
  );
}
