import React, { useState } from "react";
import { Modal } from "antd";
import { BsX } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { listServerUrl } from "../../../utils";
import { revokeSharedListAccess } from "../../../redux/tasksSlice";
import axios from "axios";

const InviteChip = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = useSelector((state) => state.app.uid);
  const sharedListMasterData = useSelector((state) => state.tasks.sharedLists);

  // Filter pending invites where userId is in pendingInvites
  const pendingInvites = Object.entries(sharedListMasterData)
    .filter(([_, listData]) => !listData.sharedWithIds.includes(userId))
    .map(([listId, listData]) => ({
      listId,
      owner: listData.sharedWith
        .filter((user) => user.userId === listData.owner)
        .map((user) => user)[0],
      listTitle: listData.title,
      listIcon: listData.icon || "📝",
    }));

  const inviteCount = pendingInvites.length;

  const dispatch = useDispatch();

  if (inviteCount === 0) return null;

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  // ... existing code ...

  const acceptInvite = async (listId, email) => {
    try {
      const idToken = await getAuth().currentUser.getIdToken(true);

      const response = await axios.post(
        `${listServerUrl}/acceptInvite`,
        {
          listId: listId,
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: { idToken: idToken },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error accepting invite:", error);
      return null;
    }
  };

  const getUserAvatar = (user) => {
    if (user.avatar_url) return user.avatar_url;
    return null;
  };

  return (
    <>
      <div
        onClick={() => setIsModalOpen(true)}
        className="flex items-center px-2 py-1 bg-blue-100 dark:bg-blue-900 rounded-full cursor-pointer hover:bg-blue-200 dark:hover:bg-blue-800 transition-colors"
      >
        <span className="text-xs font-semibold text-blue-800 dark:text-blue-200">
          {inviteCount} invite{inviteCount !== 1 ? "s" : ""}
        </span>
      </div>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={550}
        destroyOnClose={true}
        closeIcon={null}
        onClick={handleModalClick}
      >
        <div className="p-6" onClick={handleModalClick}>
          <div className="flex flex-col gap-0 items-start mb-6">
            <div className="flex justify-between flex-row w-full">
              <h2 className="text-lg font-medium">List invites</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-neutral-500 hover:text-neutral-700"
              >
                <BsX className="h-6 w-6" />
              </button>
            </div>
            <div>
              <p className="text-sm text-neutral-500">
                You have {inviteCount} pending list invite
                {inviteCount !== 1 ? "s" : ""}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            {pendingInvites.map((invite) => (
              <div
                key={invite.listId}
                className="flex items-center justify-between p-4rounded-lg"
              >
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-neutral-100 dark:bg-neutral-700 rounded-lg flex items-center justify-center text-xl">
                    {invite.listIcon}
                  </div>
                  <div>
                    <div className="font-medium text">{invite.listTitle}</div>
                    <div className="flex flex-row gap-2 items-center text-sm text-neutral-500">
                      <div className="flex flex-row gap-1 items-center">
                        {getUserAvatar(invite.owner) ? (
                          <img
                            src={getUserAvatar(invite.owner)}
                            alt={invite.owner?.name}
                            className="w-4 h-4 rounded-full"
                          />
                        ) : (
                          <div className="w-4 h-4 rounded-full border-neutral-500 border font-semibold dark:border-neutral-300 flex items-center justify-center text-black dark:text-white text-xs">
                            {invite.owner?.name.charAt(0).toUpperCase()}
                          </div>
                        )}
                        <span>{invite.owner?.name} invited you</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    className="button secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Handle decline logic here

                      const user = getAuth().currentUser;
                      const email = user.email;

                      dispatch(
                        revokeSharedListAccess({
                          sharedListId: invite.listId,
                          email,
                        })
                      );
                    }}
                  >
                    Decline
                  </button>
                  <button
                    className="button primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Handle accept logic here
                      const user = getAuth().currentUser;
                      const email = user.email;

                      if (email) {
                        // Post request to listServerUrl/acceptInvite
                        acceptInvite(invite.listId, email);
                      }
                    }}
                  >
                    Accept
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InviteChip;
