import "./Settings.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CogIcon,
  SupportIcon,
  AdjustmentsIcon,
  TagIcon,
  UserIcon,
  SparklesIcon,
  CalendarIcon,
  HeartIcon,
  HiCommandLine,
  CommandLineIcon,
  Cog6ToothIcon,
  CakeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChartPieIcon,
  EnvelopeOpenIcon,
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  CloudArrowDownIcon,
  BoltIcon,
  RocketLaunchIcon,
  BeakerIcon,
  Battery50Icon,
  UserCircleIcon,
  SquaresPlusIcon,
  BellIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

import { TbPlug, TbTargetArrow } from "react-icons/tb";

import { GrMagic } from "react-icons/gr";

import Account from "./Account";
import Customize from "./Customize";
import LabelManager from "../Labels/LabelManager";
import ManageSubscription from "./ManageSubscription";
import Support from "./Support";
import Calendars from "./Calendars";

import { Default, Mobile } from "../../mediaUtils";

import { getAuth, signOut } from "firebase/auth";
import { getInitials } from "../../utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { IoClose } from "react-icons/io5";
import {
  ArrowLeftOnRectangleIcon,
  EnvelopeIcon,
  LogoutIcon,
} from "@heroicons/react/24/outline";

import {
  toggleLabelManager,
  toggleSettings,
  showPersonalizationSettings,
  updateCurrentUser,
} from "../../redux/appSlice";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { LifebuoyIcon } from "@heroicons/react/24/solid";
import KeyboardShortcuts from "../Generics/KeyboardShortcuts";
import { useNavigate } from "react-router-dom";
import EmailForwarding from "./EmailForwarding";
import DownloadApps from "./DownloadApps";
import { Drawer, Tooltip, notification } from "antd";
import { FiMenu } from "react-icons/fi";
import API from "./API";
import ImportData from "./ImportData";
import PowerFeatures from "./PowerFeatures";
import DueDateSettings from "./Power Features/DueDateSettings";
import AccountData from "./AccountData";
import Integrations from "./Integrations";
import { HiBell } from "react-icons/hi2";
import Notifications from "./Notifications";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { deleteCalendarDB } from "../../redux/calendars/indexDbSchema";
import { FaExclamationCircle } from "react-icons/fa";
import { FaTriangleExclamation } from "react-icons/fa6";

export default function SettingsContent({
  toolTipActiveForTab,
  setToolTipActiveForTab,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState("account");

  const {
    labelManagerVisible,
    settingsVisible,
    personalizationSettingsVisible,
    settingsPageVisible, // will be "integrations" or something
  } = useSelector((state) => state.app);

  const { avatar_url, name } = useSelector((state) => state.app.currentUser);
  const user_devices = useSelector(
    (state) => state.app.currentUser.user_devices || {}
  );
  const { calendar_accounts = {} } = useSelector(
    (state) => state.app.currentUser
  );

  const hasCalendarErrors = Object.values(calendar_accounts || {}).some(
    (account) => account?.status === "error"
  );

  useEffect(() => {
    if (settingsPageVisible) {
      // If its equal to any of the types, set that type as active
      if (Object.keys(types).includes(settingsPageVisible)) {
        console.log("settingsPageVisible", settingsPageVisible);
        setActive(settingsPageVisible);
      } else {
        // If its not in the types, set it to account
        setActive("account");
      }
    } else {
      if (labelManagerVisible) {
        setActive("labels");
      } else if (personalizationSettingsVisible) {
        setActive("customization");
      } else {
        setActive("account");
      }
    }
  }, [
    labelManagerVisible,
    personalizationSettingsVisible,
    settingsPageVisible,
  ]);

  const auth = getAuth();
  const user = auth.currentUser;

  const types = {
    account: "Account Settings",
    subscription: "Membership & Billing",
    labels: "Labels",
    customization: "Customize Ellie",
    support: "Help & Support",
    calendars: "Your Calendars",
    emailForwarding: "Email Forwarding",
    apps: "Download Apps",
    importData: "Import Data",
    accountData: "Account Data",
    api: "API Token",
    "power-features": "Power Features",
    due_dates: "Due Dates",
    integrations: "Integrations",
    notifications: "Notifications",
  };

  const [shortcutsVisible, setShortcutsVisible] = useState(false);

  useEffect(() => {
    window.Canny("initChangelog", {
      appID: "637d2ce47a741d0ff12b8c61",
      position: "top",
      align: "right",
    });
  }, []);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
      }
  );

  const email_forwarding_id = useSelector(
    (state) => state.app.currentUser.email_forwarding_id || null
  );

  return (
    <div className="settings-container">
      <KeyboardShortcuts
        visible={shortcutsVisible}
        setVisible={setShortcutsVisible}
      />

      <Mobile>
        <Drawer
          placement="left"
          closable={false}
          onClose={() => {
            setDrawerVisible(false);
          }}
          open={drawerVisible}
          width={300}
          style={{
            backgroundColor: "var(--settings-sidebar-background-color)",
          }}
        >
          <div className="settings-sidebar">
            <div className="settings-sidebar-header">
              <div className="settings-sidebar-header-left">
                {avatar_url ? (
                  <img
                    className="settings-sidebar-avatar"
                    src={avatar_url}
                    alt="avatar"
                  />
                ) : (
                  <div className="settings-sidebar-avatar-initials">
                    {getInitials(name)}
                  </div>
                )}
              </div>
              <div className="settings-sidebar-header-right">
                <div className="settings-sidebar-header-right-name">{name}</div>
                <div className="settings-sidebar-header-right-email">
                  {user.email}
                </div>
              </div>
            </div>

            <div className="settings-section">
              <div className="settings-section-header">User settings</div>
              <div
                onClick={() => {
                  setActive("account");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "account" ? " active" : "")
                }
              >
                <UserCircleIcon className="settings-sidebar-icon" />
                Account Settings
              </div>
              <div
                onClick={() => {
                  setActive("subscription");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "subscription" ? " active" : "")
                }
              >
                <SparklesIcon className="settings-sidebar-icon" />
                Subscription
              </div>
            </div>

            <div className="settings-section-divider" />

            <div className="settings-section-divider" />
            <div className="settings-section">
              <div className="settings-section-header">App Settings</div>
              <div
                onClick={() => {
                  setActive("customization");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "customization" ? " active" : "")
                }
              >
                <Cog6ToothIcon className="settings-sidebar-icon" />
                General settings
              </div>
              <div
                onClick={() => {
                  setActive("calendars");
                }}
                className={
                  "settings-sidebar-item relative" +
                  (active === "calendars" ? " active" : "")
                }
              >
                <div className="flex items-center w-full">
                  <CalendarIcon className="settings-sidebar-icon" />
                  <div className="flex items-center gap-2">
                    <span>Calendar accounts</span>
                    {hasCalendarErrors && (
                      <span className="px-2 py-0.5 text-xs font-medium bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-300 rounded-full">
                        Issues
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div
                onClick={() => {
                  setActive("labels");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "labels" ? " active" : "")
                }
              >
                <TagIcon className="settings-sidebar-icon" />
                Labels
              </div>
            </div>

            <div className="settings-section-divider" />

            <div className="settings-section">
              <div className="settings-section-header">Power features</div>

              <div
                onClick={() => {
                  setActive("power-features");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "power-features" ? " active" : "")
                }
              >
                <BoltIcon className="settings-sidebar-icon" />
                Toggle power features
              </div>

              {power_feature_settings &&
                power_feature_settings.due_dates?.enabled && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "due_dates"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("due_dates");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        setActive("due_dates");
                        if (setToolTipActiveForTab) {
                          setToolTipActiveForTab(null);
                        }
                      }}
                      className={
                        "settings-sidebar-item" +
                        (active === "due_dates" ? " active" : "")
                      }
                    >
                      <TbTargetArrow className="settings-sidebar-icon h-[18px] w-[18px]" />
                      Due dates
                    </div>
                  </Tooltip>
                )}

              {email_forwarding_id != null &&
                email_forwarding_id.length > 0 && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "emailForwarding"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("emailForwarding");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        setActive("emailForwarding");
                        if (setToolTipActiveForTab) {
                          setToolTipActiveForTab(null);
                        }
                      }}
                      className={
                        "settings-sidebar-item" +
                        (active === "emailForwarding" ? " active" : "")
                      }
                    >
                      <AtSymbolIcon className="settings-sidebar-icon" />
                      Email forwarding
                    </div>
                  </Tooltip>
                )}

              {power_feature_settings &&
                power_feature_settings.analytics_enabled && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "analytics"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("analytics");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        dispatch(toggleSettings());
                        navigate("/analytics");
                      }}
                      className={"settings-sidebar-item"}
                    >
                      <ChartPieIcon className="settings-sidebar-icon" />
                      Analytics
                    </div>
                  </Tooltip>
                )}

              {power_feature_settings &&
                power_feature_settings.api_access_enabled && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "api_access"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("api_access");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        setActive("api");
                        if (setToolTipActiveForTab) {
                          setToolTipActiveForTab(null);
                        }
                      }}
                      className={
                        "settings-sidebar-item" +
                        (active === "api" ? " active" : "")
                      }
                    >
                      <BoltIcon className="settings-sidebar-icon" />
                      API Token
                    </div>
                  </Tooltip>
                )}
            </div>

            <div className="settings-section-divider" />

            <div className="settings-section">
              <div
                onClick={() => {
                  setActive("support");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "support" ? " active" : "")
                }
              >
                {" "}
                <LifebuoyIcon className="settings-sidebar-icon" />
                Help & Support
              </div>

              <div
                onClick={() => {
                  setActive("apps");
                }}
                className={
                  "settings-sidebar-item" + (active === "apps" ? " active" : "")
                }
              >
                {" "}
                <DevicePhoneMobileIcon className="settings-sidebar-icon" />
                Download apps
              </div>
              {/* <div
                onClick={() => {
                  setActive("importData");
                  setDrawerVisible(false);
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "importData" ? " active" : "")
                }
              >
                {" "}
                <CloudArrowDownIcon className="settings-sidebar-icon" />
                Import Data
              </div> */}
              <div
                onClick={() => {
                  setActive("accountData");
                  setDrawerVisible(false);
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "accountData" ? " active" : "")
                }
              >
                {" "}
                <CloudArrowDownIcon className="settings-sidebar-icon" />
                Account Data
              </div>
            </div>

            <div className="settings-section-divider" />

            <div
              onClick={async () => {
                signOut(auth)
                  .then(() => {
                    // Sign-out successful.

                    dispatch(deleteCalendarDB());
                  })
                  .catch((error) => {
                    // An error happened.
                  });
              }}
              className="log-out-button"
            >
              <ArrowLeftOnRectangleIcon className="log-out-icon" />
              Log out
            </div>
          </div>
        </Drawer>
      </Mobile>
      <Default>
        <div className="settings-sidebar">
          <div className="settings-sidebar-header">
            <div className="settings-sidebar-header-left">
              {avatar_url ? (
                <img
                  className="settings-sidebar-avatar"
                  src={avatar_url}
                  alt="avatar"
                />
              ) : (
                <div className="settings-sidebar-avatar-initials">
                  {getInitials(name)}
                </div>
              )}
            </div>
            <div className="settings-sidebar-header-right">
              <div className="settings-sidebar-header-right-name">{name}</div>
              <div className="settings-sidebar-header-right-email">
                {user.email}
              </div>
            </div>
          </div>

          <div className="settings-section">
            <div className="settings-section-header">User settings</div>
            <div
              onClick={() => {
                setActive("account");
              }}
              className={
                "settings-sidebar-item" +
                (active === "account" ? " active" : "")
              }
            >
              <UserCircleIcon className="settings-sidebar-icon" />
              Account Settings
            </div>
            <div
              onClick={() => {
                setActive("subscription");
              }}
              className={
                "settings-sidebar-item" +
                (active === "subscription" ? " active" : "")
              }
            >
              <SparklesIcon className="settings-sidebar-icon" />
              Subscription
            </div>
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section-divider" />
          <div className="settings-section">
            <div className="settings-section-header">App Settings</div>
            <div
              onClick={() => {
                setActive("customization");
              }}
              className={
                "settings-sidebar-item" +
                (active === "customization" ? " active" : "")
              }
            >
              <Cog6ToothIcon className="settings-sidebar-icon" />
              General settings
            </div>
            {isDesktopApp() && (
              <div
                onClick={() => {
                  setActive("notifications");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "notifications" ? " active" : "")
                }
              >
                <BellIcon className="settings-sidebar-icon" />
                Notifications
              </div>
            )}
            <div
              onClick={() => {
                setActive("calendars");
              }}
              className={
                "settings-sidebar-item relative" +
                (active === "calendars" ? " active" : "")
              }
            >
              <div className="flex items-center w-full">
                <CalendarIcon className="settings-sidebar-icon" />
                <div className="flex items-center gap-2">
                  <span>Calendar accounts</span>
                  {hasCalendarErrors && (
                    <span className="px-2 py-0.5 text-xs font-medium bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-300 rounded-full">
                      Issues
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                setActive("labels");
              }}
              className={
                "settings-sidebar-item" + (active === "labels" ? " active" : "")
              }
            >
              <TagIcon className="settings-sidebar-icon" />
              Labels
            </div>

            <div
              onClick={() => {
                setActive("integrations");
              }}
              className={
                "settings-sidebar-item" +
                (active === "integrations" ? " active" : "")
              }
            >
              <SquaresPlusIcon className="settings-sidebar-icon" />
              Integrations
            </div>
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section">
            <div className="settings-section-header">Power features</div>

            <div
              onClick={() => {
                setActive("power-features");
              }}
              className={
                "settings-sidebar-item" +
                (active === "power-features" ? " active" : "")
              }
            >
              <BoltIcon className="settings-sidebar-icon" />
              Toggle power features
            </div>

            {power_feature_settings &&
              power_feature_settings.due_dates?.enabled && (
                <Tooltip
                  placement="left"
                  title="Setting now available ✨"
                  open={toolTipActiveForTab === "due_dates"}
                  onOpenChange={(open) => {
                    if (setToolTipActiveForTab) {
                      if (open) {
                        setToolTipActiveForTab("due_dates");
                      } else {
                        setToolTipActiveForTab(null);
                      }
                    }
                  }}
                  trigger={[]}
                >
                  <div
                    onClick={() => {
                      setActive("due_dates");
                      if (setToolTipActiveForTab) {
                        setToolTipActiveForTab(null);
                      }
                    }}
                    className={
                      "settings-sidebar-item" +
                      (active === "due_dates" ? " active" : "")
                    }
                  >
                    <TbTargetArrow className="settings-sidebar-icon h-[18px] w-[18px]" />
                    Due dates
                  </div>
                </Tooltip>
              )}

            {email_forwarding_id != null && email_forwarding_id.length > 0 && (
              <Tooltip
                placement="left"
                title="Setting now available ✨"
                open={toolTipActiveForTab === "emailForwarding"}
                onOpenChange={(open) => {
                  if (setToolTipActiveForTab) {
                    if (open) {
                      setToolTipActiveForTab("emailForwarding");
                    } else {
                      setToolTipActiveForTab(null);
                    }
                  }
                }}
                trigger={[]}
              >
                <div
                  onClick={() => {
                    setActive("emailForwarding");
                    if (setToolTipActiveForTab) {
                      setToolTipActiveForTab(null);
                    }
                  }}
                  className={
                    "settings-sidebar-item" +
                    (active === "emailForwarding" ? " active" : "")
                  }
                >
                  <AtSymbolIcon className="settings-sidebar-icon" />
                  Email forwarding
                </div>
              </Tooltip>
            )}

            {power_feature_settings &&
              power_feature_settings.api_access_enabled && (
                <Tooltip
                  placement="left"
                  title="Setting now available ✨"
                  open={toolTipActiveForTab === "api_access"}
                  onOpenChange={(open) => {
                    if (setToolTipActiveForTab) {
                      if (open) {
                        setToolTipActiveForTab("api_access");
                      } else {
                        setToolTipActiveForTab(null);
                      }
                    }
                  }}
                  trigger={[]}
                >
                  <div
                    onClick={() => {
                      setActive("api");
                      if (setToolTipActiveForTab) {
                        setToolTipActiveForTab(null);
                      }
                    }}
                    className={
                      "settings-sidebar-item" +
                      (active === "api" ? " active" : "")
                    }
                  >
                    <BoltIcon className="settings-sidebar-icon" />
                    API Token
                  </div>
                </Tooltip>
              )}
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section">
            <div
              onClick={() => {
                setActive("apps");
              }}
              className={
                "settings-sidebar-item" + (active === "apps" ? " active" : "")
              }
            >
              {" "}
              <DevicePhoneMobileIcon className="settings-sidebar-icon" />
              Download apps
            </div>
            {/* <div
              onClick={() => {
                setActive("importData");
                setDrawerVisible(false);
              }}
              className={
                "settings-sidebar-item" +
                (active === "importData" ? " active" : "")
              }
            >
              {" "}
              <CloudArrowDownIcon className="settings-sidebar-icon" />
              Import Data
            </div> */}
            <div
              onClick={() => {
                setActive("accountData");
                setDrawerVisible(false);
              }}
              className={
                "settings-sidebar-item" +
                (active === "accountData" ? " active" : "")
              }
            >
              {" "}
              <CloudArrowDownIcon className="settings-sidebar-icon" />
              Account Data
            </div>
          </div>

          <div className="settings-section-divider" />

          <div
            onClick={async () => {
              // First, lets check check if there is an FCM Token active
              // Get ellie_fcm_token from localstorage if it exists
              const fcmToken = await localStorage.getItem("ellie_fcm_token");

              // If there is an FCM token and its in the user_devices
              if (fcmToken && user_devices?.[fcmToken]?.enabled) {
                try {
                  await dispatch(
                    updateCurrentUser({
                      newValues: {
                        user_devices: {
                          [fcmToken]: {
                            enabled: false,
                            last_updated: new Date(),
                          },
                        },
                      },
                      previousValues: {
                        user_devices: user_devices,
                      },
                    })
                  );
                } catch (error) {
                  console.log(error);
                }
              }

              signOut(auth)
                .then(() => {
                  // Sign-out successful.
                  // clear FCM token if its there
                  localStorage.removeItem("ellie_fcm_token");
                  dispatch(deleteCalendarDB());
                })
                .catch((error) => {
                  // An error happened.
                });
            }}
            className="log-out-button"
          >
            <ArrowLeftOnRectangleIcon className="log-out-icon" />
            Log out
          </div>
        </div>
      </Default>

      <div className="settings-content">
        <div className="settings-content-header">
          <Mobile>
            {(!labelManagerVisible || !personalizationSettingsVisible) && (
              <FiMenu
                className="menu-icon"
                onClick={() => setDrawerVisible(true)}
              />
            )}
          </Mobile>
          <div>{types[active]}</div>
          <Mobile>
            {(labelManagerVisible || personalizationSettingsVisible) && (
              <div
                onClick={() => {
                  if (settingsVisible) {
                    dispatch(toggleSettings());
                  }

                  if (labelManagerVisible) {
                    dispatch(toggleLabelManager());
                  }

                  if (personalizationSettingsVisible) {
                    dispatch(showPersonalizationSettings());
                  }

                  if (toolTipActiveForTab && setToolTipActiveForTab) {
                    setToolTipActiveForTab(null);
                  }
                }}
                className="close-icon-container"
              >
                <IoClose className="close-icon" />
              </div>
            )}
          </Mobile>
          <Default>
            <div
              onClick={() => {
                if (settingsVisible) {
                  dispatch(toggleSettings());
                }

                if (labelManagerVisible) {
                  dispatch(toggleLabelManager());
                }

                if (personalizationSettingsVisible) {
                  dispatch(showPersonalizationSettings());
                }

                if (toolTipActiveForTab && setToolTipActiveForTab) {
                  setToolTipActiveForTab(null);
                }
              }}
              className="close-icon-container"
            >
              <IoClose className="close-icon" />
            </div>
          </Default>
        </div>
        <div className={`content ${active === "customization" ? "!p-0" : ""}`}>
          {active === "account" && <Account />}
          {active === "customization" && <Customize />}
          {active === "power-features" && (
            <PowerFeatures
              setToolTipActiveForTab={setToolTipActiveForTab || (() => {})}
            />
          )}
          {active === "labels" && <LabelManager />}
          {active === "support" && <Support />}
          {active === "subscription" && <ManageSubscription />}
          {active === "calendars" && <Calendars />}
          {active === "emailForwarding" && <EmailForwarding />}
          {active === "apps" && <DownloadApps />}
          {active === "api" && <API />}
          {active === "importData" && <ImportData />}
          {active === "accountData" && <AccountData />}
          {active === "due_dates" && <DueDateSettings />}
          {active === "integrations" && <Integrations />}
          {active === "notifications" && <Notifications />}
        </div>
      </div>
    </div>
  );
}

export function SettingsCell({ icon, title, description, onClick }) {
  return (
    <div onClick={onClick} className="account-page-cell">
      <div className="account-page-cell-left">
        {icon}
        <div className="account-page-meta">
          <div className="account-page-cell-title">{title}</div>
          <div className="account-page-cell-description">{description}</div>
        </div>
      </div>
      <ChevronRightIcon className="chevron-right-icon" />
    </div>
  );
}
