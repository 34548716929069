import { useSelector } from "react-redux";

function SharedUsers({ listId, onClick }) {
  const sharedList = useSelector((state) => state.tasks.sharedLists[listId]);

  return (
    <div className="flex -space-x-2 opacity-70 hover:opacity-100" onClick={onClick}>
      {sharedList?.sharedWith &&
        sharedList.sharedWith.map((user) => (
          <div
            key={user.userId}
            className="rounded-full w-[20px] h-[20px]"
          >
            {user.avatar_url ? (
              <img
                src={user.avatar_url}
                alt={user.name}
                className="w-[19px] h-[19px] object-cover border-white border-[1px] dark:border-neutral-300 rounded-full"
              />
            ) : (
              <div className="w-[18px] h-[18px] rounded-full bg-white border-neutral-800 border-[1px] font-medium dark:border-neutral-300 shadow-sm flex items-center justify-center text-black dark:text-white text-[10px]">
                {user.name?.[0]?.toUpperCase()}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default SharedUsers;
