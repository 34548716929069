import { Drawer } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BiCheckCircle } from "react-icons/bi";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useSelector } from "react-redux";
import {
  generateCompletedTasksPerDayPerLabel,
  getNumberOfTasksCompletedThisPeriodAndLast,
  transformDataForActualVsEstimated,
} from "../AnalyticsFunctions";
import TaskTable from "../TaskTable";
import { analytics } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SmallCompletedTasksCard({ taskData, dateRange }) {
  const { data: labels } = useSelector((state) => state.labels);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [thisPeriod, setThisPeriod] = useState([]);
  const [lastPeriod, setLastPeriod] = useState([]);
  const [actualTime, setActualTime] = useState(0);

  useEffect(() => {
    const { thisPeriod, lastPeriod } =
      getNumberOfTasksCompletedThisPeriodAndLast(
        taskData,
        dateRange.dates,
        dateRange.lastPeriodDates
      );

    setThisPeriod(thisPeriod);
    setLastPeriod(lastPeriod);

    // Calculate total actual time (in seconds)
    const totalActualTime = thisPeriod.reduce(
      (total, task) => total + (task.actual_time || 0),
      0
    );
    setActualTime(totalActualTime);
  }, [taskData, dateRange, labels]);

  var percentChange = thisPeriod.length / lastPeriod.length / thisPeriod.length;

  if (percentChange === Infinity) {
    percentChange = 100;
  } else if (isNaN(percentChange) === true) {
    percentChange = 0;
  } else {
    percentChange = (percentChange * 100).toFixed(0);
  }

  // Function to format time in hours and minutes
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };

  return (
    <div
      className="chart sl"
      style={{
        gridArea: "tasks-completed-small",
      }}
    >
      <Drawer
        title="Tasks"
        placement="right"
        closable={true}
        onClose={() => {
          setSelectedTasks(null);
        }}
        open={selectedTasks !== null}
        width={700}
        zIndex={10}
      >
        <TaskTable
          tasks={selectedTasks}
          labels={labels}
          showCompletionDate={true}
        />
      </Drawer>
      <div
        onClick={() => {
          analytics("Analytics drilldown", {
            type: "small-completed-tasks-card",
          });
          setSelectedTasks(thisPeriod);
        }}
        className="title"
      >
        <BiCheckCircle className="title-icon" /> <span>Tasks completed</span>
      </div>
      <div className="value flex flex-row items-center">
        {thisPeriod.length}
        {actualTime > 0 && (
          <span className="text-base text-neutral-500 dark:text-neutral-400 ml-2">
            ({formatTime(actualTime)})
          </span>
        )}
      </div>
      <div className="change-chip">
        {percentChange >= 0 ? (
          <FiArrowUp className="up" />
        ) : (
          <FiArrowDown className="down" />
        )}
        {percentChange}% vs {getLastPeriodText(dateRange)}
      </div>
    </div>
  );
}

function getLastPeriodText(dateRange) {
  // Current week
  if (dateRange.id == "currentWorkWeek") {
    return "last work week";
  }

  if (dateRange.id == "7days") {
    return "last 7 days";
  }

  if (dateRange.id == "14days") {
    return "last 14 days";
  }

  if (dateRange.id == "30days") {
    return "last 30 days";
  }

  if (dateRange.id == "3months") {
    return "last 3 months";
  }

  return "last period";
}
