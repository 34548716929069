import React, { useState } from "react";
import { Modal, Popover } from "antd";
import { BsX } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { revokeSharedListAccess } from "../../../redux/tasksSlice";
import { listServerUrl } from "../../../utils";

const shareList = async (listId, email, userId) => {
  try {
    const response = await fetch(`${listServerUrl}/shareList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        listId: listId,
        invites: [email],
        userId: userId,
      }),
    });
    return await response.json();
  } catch (error) {
    console.error("Error sharing list:", error);
    return null;
  }
};

export default function ManageListAccess({ isOpen, onClose, listId }) {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.app.uid);
  const sharedList = useSelector((state) => state.tasks.sharedLists[listId]);
  const [newEmail, setNewEmail] = useState("");
  const [openPopover, setOpenPopover] = useState(null);

  const handleAddUser = async (e) => {
    e.stopPropagation();
    if (!newEmail) return;

    const result = await shareList(listId, newEmail, userId);
    if (result) {
      setNewEmail("");
    }
  };

  const handleRevokeInvite = (email) => {
    // Revoke invite by email
    dispatch(
      revokeSharedListAccess({
        sharedListId: listId,
        email,
      })
    );
  };

  const getUserAvatar = (user) => {
    if (user.avatar_url) return user.avatar_url;
    return null;
  };

  const isOwner = (user) => user.userId === sharedList?.owner;

  const isCurrentUserOwner = () => sharedList?.owner === userId;

  const handleRemoveUser = (user) => {
    if (!isOwner(user)) {
      dispatch(
        revokeSharedListAccess({
          sharedListId: listId,
          userId: user.userId,
        })
      );
      setOpenPopover(null);
    }
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const getPopoverContent = (user) => (
    <div className="flex flex-col gap-3 p-4 max-w-[350px] whitespace-normal select-text border rounded-lg">
      <div className="text-sm">
        <div className="font-semibold mb-2">Remove user from list</div>
        <div className="text-neutral-600 dark:text-neutral-400 text-xs">
          This will remove the list and all its tasks from {user.name}'s
          account. They will no longer have access to this list.
        </div>
      </div>
      <div className="flex gap-2 justify-end mt-2">
        <button onClick={() => setOpenPopover(null)} className="button">
          Cancel
        </button>
        <button
          onClick={() => handleRemoveUser(user)}
          className="button primary"
        >
          Remove
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onCancel={(e) => {
        e.stopPropagation();
        onClose();
      }}
      footer={null}
      width={550}
      destroyOnClose={true}
      closeIcon={null}
      onClick={handleModalClick}
    >
      <div className="p-6" onClick={handleModalClick}>
        <div className="flex justify-between mb-4">
          <div>
            <h2 className="text-lg font-medium">Manage list access</h2>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="text-neutral-500 hover:text-neutral-700"
          >
            <BsX className="h-6 w-6" />
          </button>
        </div>

        <div className="flex flex-col gap-3 items-start w-full">
          {(!sharedList || isCurrentUserOwner()) && (
            <div className="flex gap-2 w-full mb-2">
              <input
                type="email"
                placeholder="Email or group, separated by commas"
                value={newEmail}
                onChange={(e) => {
                  e.stopPropagation();
                  setNewEmail(e.target.value);
                }}
                onClick={(e) => e.stopPropagation()}
                className="text-sm font-medium bg-white-100 border py-2 px-2 outline-none rounded-lg flex-1 dark:bg-neutral-700 dark:border-neutral-500"
              />
              <button onClick={handleAddUser} className="button primary px-3">
                Invite user
              </button>
            </div>
          )}

          <div className="text-sm font-medium text-neutral-500">
            People who have access
          </div>
          <div className="space-y-3 w-full">
            {sharedList?.sharedWith?.map((user) => (
              <div
                key={user.userId}
                className="flex items-center justify-between w-full"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex items-center gap-3">
                  {getUserAvatar(user) ? (
                    <img
                      src={getUserAvatar(user)}
                      alt={user.name}
                      className="w-8 h-8 rounded-full"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full border-neutral-500 border font-semibold dark:border-neutral-300 flex items-center justify-center text-black dark:text-white text-xs">
                      {user.name.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div>
                    <div className="font-medium flex items-center gap-2">
                      {user.name} {user.userId === userId && "(You)"}
                      {isOwner(user) && (
                        <div className="flex flex-row gap-2 items-center text-xs px-2 py-1 bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 rounded-full">
                          <span>Owner</span>
                        </div>
                      )}
                    </div>
                    <div className="text-sm text-neutral-500">{user.email}</div>
                  </div>
                </div>
                {(!sharedList || isCurrentUserOwner()) && (
                  <Popover
                    open={openPopover === user.userId}
                    onOpenChange={(visible) => {
                      setOpenPopover(visible ? user.userId : null);
                    }}
                    content={getPopoverContent(user)}
                    trigger="click"
                    placement="left"
                    overlayClassName="remove-user-popover"
                  >
                    <button
                      disabled={isOwner(user)}
                      className={`text-sm px-3 py-1.5 rounded-md ${
                        isOwner(user)
                          ? "text-sm text-neutral-500 flex flex-row gap-2 items-center hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-md py-2 px-2 cursor-not-allowed opacity-20"
                          : "text-sm text-neutral-500 flex flex-row gap-2 items-center hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-md py-2 px-2 cursor-pointer"
                      }`}
                    >
                      Remove
                    </button>
                  </Popover>
                )}
              </div>
            ))}
          </div>

          {/* Pending Invites Section */}
          {sharedList?.pendingInvites &&
            sharedList.pendingInvites.length > 0 && (
              <>
                <div className="text-sm font-medium text-neutral-500 mt-6">
                  Pending invites
                </div>
                <div className="space-y-3 w-full">
                  {sharedList.pendingInvites.map((email) => (
                    <div
                      key={email}
                      className="flex items-center justify-between w-full"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-full border-neutral-500 border font-semibold dark:border-neutral-300 flex items-center justify-center text-black dark:text-white text-xs">
                          {email.charAt(0).toUpperCase()}
                        </div>
                        <div className="text-sm text-neutral-500">{email}</div>
                      </div>
                      {(!sharedList || isCurrentUserOwner()) && (
                        <button
                          onClick={() => handleRevokeInvite(email)}
                          className="text-sm text-neutral-500 hover:text-neutral-700 flex flex-row gap-2 items-center hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-md py-2 px-3"
                        >
                          Revoke invite
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
        </div>
      </div>
    </Modal>
  );
}
