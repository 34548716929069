import React, { useState } from "react";
import { HiCheck, HiPlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { TbSelector } from "react-icons/tb";
import { FiMoreHorizontal } from "react-icons/fi";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Dropdown, Popover } from "antd";
import { isEqual } from "lodash";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import CreateList from "./Lists/CreateList";
import List from "./List";
import MiniList from "./MiniList";
import DeadlineLists from "./DueDates/DeadlineLists";
import AddTaskCard from "../Kanban/Column/AddTaskCard";
import CreateCard from "../Kanban/Card/CreateCard";
import { updateCurrentUser } from "../../redux/appSlice";
import { softListDelete } from "../../redux/tasksSlice";
import InviteChip from "./Lists/InvitesChip";

export default function SidebarContent({
  taskOrder,
  createActive,
  setCreateActive,
  navigatedViaKeyboard,
  setNavigatedViaKeyboard,
  indexSelected,
  setIndexSelected,
  manuallySelectColumn,
  columnSelected,
  activelyReorderingSidebar,
  sortedLists,
}) {
  const userId = useSelector((state) => state.app.uid);
  const hide_sidebar = useSelector(
    (state) => state.app.currentUser.hide_sidebar
  );
  const sidebar_layout = useSelector(
    (state) => state.app.currentUser.sidebar_layout || "single_list"
  );
  const sidebar_mode = useSelector(
    (state) => state.app.currentUser.sidebar_mode || "lists"
  );
  const tasks = useSelector((state) => state.tasks.data);
  const lists = useSelector((state) => state.tasks.lists || {});
  const selectedList = useSelector(
    (state) => state.app.currentUser?.selectedList || "brain_dump"
  );
  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        number_of_days_to_show_indicator: 7,
      },
    (prev, next) => isEqual(prev, next)
  );

  const [listSelectorActive, setListSelectorActive] = useState(false);
  const [listCreateActive, setListCreateActive] = useState(false);
  const [listEditActive, setListEditActive] = useState(false);

  const dispatch = useDispatch();

  // Separate lists into personal and shared
  const personalLists = sortedLists.filter((listId) => !lists[listId]?.shared);
  const sharedLists = sortedLists.filter((listId) => lists[listId]?.shared);

  const sharedListMasterData = useSelector((state) => state.tasks.sharedLists);

  // where userId is in pendingInvites
  const pendingListInvites = Object.keys(sharedListMasterData).filter(
    (listId) => !sharedListMasterData[listId].sharedWithIds.includes(userId)
  );

  const renderSingleListView = () => (
    <>
      <div className="braindump-header">
        <div className="braindump-header-title-container">
          <CreateList
            active={listCreateActive || listEditActive}
            setActive={
              listCreateActive ? setListCreateActive : setListEditActive
            }
            mode={listCreateActive ? "create" : "edit"}
            listToEdit={listEditActive ? lists[selectedList] : null}
          />
          <div className="mb-2 flex flex-row items-center justify-between w-full px-1">
            <Popover
              open={listSelectorActive}
              onOpenChange={setListSelectorActive}
              content={
                <div className="flex flex-col gap-2 w-64">
                  <div className="flex flex-col gap-1 pt-2">
                    <ListItem
                      title="Brain Dump"
                      icon="🧠"
                      selected={selectedList === "brain_dump"}
                      taskCount={
                        taskOrder?.["brain_dump"]?.order?.filter(
                          (taskId) => tasks[taskId]
                        ).length
                      }
                      onClick={() => {
                        dispatch(
                          updateCurrentUser({
                            newValues: { selectedList: "brain_dump" },
                            previousValues: { selectedList },
                          })
                        );
                        setListSelectorActive(false);
                      }}
                    />
                    {Object.values(lists).map((list) => (
                      <ListItem
                        key={list.id}
                        title={list.title}
                        icon={list.icon}
                        selected={selectedList === list.id}
                        taskCount={
                          taskOrder?.[list.id]?.order?.filter(
                            (taskId) => tasks[taskId]
                          ).length
                        }
                        onClick={() => {
                          dispatch(
                            updateCurrentUser({
                              newValues: { selectedList: list.id },
                              previousValues: { selectedList },
                            })
                          );
                          setListSelectorActive(false);
                        }}
                      />
                    ))}
                  </div>
                  <div
                    onClick={() => {
                      setListCreateActive(true);
                      setListSelectorActive(false);
                    }}
                    className="flex flex-col gap-0 border-t py-1 dark:border-t-neutral-700"
                  >
                    <div className="flex flex-row gap-2 items-center text-sm text-neutral-700 dark:text-neutral-400 font-medium rounded-lg cursor-pointer hover:bg-neutral-100 hover:dark:bg-neutral-600 px-3 py-2">
                      <HiPlus className="text-lg text-neutral-400" />
                      <div>New List</div>
                    </div>
                  </div>
                </div>
              }
              placement="bottomLeft"
              trigger="click"
            >
              <div className="flex flex-row gap-1 items-center px-1 border border-transparent rounded-md cursor-pointer hover:border hover:border-neutral-300 hover:bg-neutral-100 hover:dark:bg-neutral-700 hover:dark:border-neutral-600">
                <div className="text-lg font-medium text-neutral-700 dark:text-neutral-200">
                  {lists[selectedList]?.icon || "🧠"}{" "}
                  {lists[selectedList]?.title || "Brain Dump"}
                </div>
                <TbSelector className="text-neutral-400 h-4 w-4" />
              </div>
            </Popover>
            {selectedList !== "brain_dump" && (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: <div>Edit list</div>,
                      icon: <PencilIcon className="h-4 w-4" />,
                      onClick: () => setListEditActive(true),
                    },
                    {
                      key: "2",
                      label: <div>Delete list</div>,
                      icon: <TrashIcon className="h-4 w-4" />,
                      onClick: () => {
                        dispatch(softListDelete({ list: lists[selectedList] }));
                        dispatch(
                          updateCurrentUser({
                            newValues: { selectedList: "brain_dump" },
                            previousValues: { selectedList },
                          })
                        );
                      },
                    },
                  ],
                }}
              >
                <FiMoreHorizontal className="text-lg text-neutral-400 cursor-pointer" />
              </Dropdown>
            )}
          </div>
        </div>
        <AddTaskCard setCreateActive={setCreateActive} />
        {createActive && (
          <CreateCard
            setCreateActive={setCreateActive}
            setNavigatedViaKeyboard={setNavigatedViaKeyboard}
            listId={selectedList}
          />
        )}
      </div>
      <List
        order={taskOrder?.[selectedList]?.order || []}
        indexSelected={indexSelected}
        manuallySelectColumn={manuallySelectColumn}
        navigatedViaKeyboard={navigatedViaKeyboard}
        setIndexSelected={setIndexSelected}
        hide_sidebar={hide_sidebar}
        listId={selectedList}
      />
    </>
  );

  const renderAllListsView = () => (
    <div className="flex flex-col gap-1 p-3 overflow-y-auto h-full">
      <CreateList
        active={listCreateActive || listEditActive}
        setActive={listCreateActive ? setListCreateActive : setListEditActive}
        mode={listCreateActive ? "create" : "edit"}
        listToEdit={listEditActive ? lists[selectedList] : null}
      />

      {/* My Lists Section */}
      <div className="text-neutral-700 text-base font-semibold flex flex-row justify-between items-center mb-1 px-1">
        <span>My Lists</span>
        <HiPlus
          onClick={() => setListCreateActive(true)}
          className="text-lg text-neutral-400 cursor-pointer hover:text-neutral-500"
        />
      </div>

      <MiniList
        order={taskOrder?.["brain_dump"]?.order || []}
        indexSelected={indexSelected}
        manuallySelectColumn={manuallySelectColumn}
        navigatedViaKeyboard={navigatedViaKeyboard}
        setIndexSelected={setIndexSelected}
        hide_sidebar={hide_sidebar}
        listId="brain_dump"
        setNavigatedViaKeyboard={setNavigatedViaKeyboard}
        key="brain_dump_sidebar"
        columnSelected={columnSelected}
        activelyReorderingSidebar={activelyReorderingSidebar}
      />

      <SortableContext
        items={personalLists}
        strategy={verticalListSortingStrategy}
        id="personal_lists"
      >
        {personalLists.map((listId) => {
          const list = lists[listId];
          if (!list) return null;
          return (
            <MiniList
              key={list.id + "_sidebar"}
              id={listId}
              order={taskOrder?.[list.id]?.order || []}
              indexSelected={indexSelected}
              manuallySelectColumn={manuallySelectColumn}
              navigatedViaKeyboard={navigatedViaKeyboard}
              setIndexSelected={setIndexSelected}
              hide_sidebar={hide_sidebar}
              listId={list.id}
              setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              activelyDragging={activelyReorderingSidebar === list.id}
              activelyReorderingSidebar={activelyReorderingSidebar}
              columnSelected={columnSelected}
            />
          );
        })}
      </SortableContext>

      {/* Shared Lists Section */}
      {((pendingListInvites && Object.keys(pendingListInvites).length > 0) ||
        sharedLists.length > 0) && (
        <>
          <div className="text-neutral-700 text-base font-semibold flex flex-row justify-between items-center mb-1 mt-2 px-1">
            <div className="flex flex-row gap-2 items-center">
              <span>Shared Lists</span>

              <InviteChip />
            </div>
            <HiPlus
              onClick={() => setListCreateActive(true)}
              className="text-lg text-neutral-400 cursor-pointer hover:text-neutral-500"
            />
          </div>

          <SortableContext
            items={sharedLists}
            strategy={verticalListSortingStrategy}
            id="shared_lists"
          >
            {sharedLists &&
              sharedLists.map((listId) => {
                const list = lists[listId];
                if (!list) return null;
                return (
                  <MiniList
                    key={list.id + "_sidebar"}
                    id={listId}
                    order={taskOrder?.[list.id]?.order || []}
                    indexSelected={indexSelected}
                    manuallySelectColumn={manuallySelectColumn}
                    navigatedViaKeyboard={navigatedViaKeyboard}
                    setIndexSelected={setIndexSelected}
                    hide_sidebar={hide_sidebar}
                    listId={list.id}
                    setNavigatedViaKeyboard={setNavigatedViaKeyboard}
                    activelyDragging={activelyReorderingSidebar === list.id}
                    activelyReorderingSidebar={activelyReorderingSidebar}
                    columnSelected={columnSelected}
                  />
                );
              })}
          </SortableContext>
        </>
      )}
    </div>
  );

  if (sidebar_mode === "due_dates" && due_date_settings.enabled) {
    return <DeadlineLists />;
  }

  if (sidebar_mode === "lists" || !due_date_settings.enabled) {
    return sidebar_layout === "single_list"
      ? renderSingleListView()
      : renderAllListsView();
  }

  return null;
}

export function ListItem({ title, icon, taskCount = 0, selected, onClick }) {
  return (
    <div
      onClick={onClick}
      className="flex flex-col gap-2 cursor-pointer hover:bg-neutral-100 hover:dark:bg-neutral-600 px-3 py-1"
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <div className="bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] from-teal-900 via-fuchsia-200 to-slate-200 rounded-md w-8 h-8 flex items-center justify-center">
            <div className="text-md">{icon}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-semibold">{title}</div>
            <div className="text-xs text-gray-500 font-medium">
              {taskCount} tasks
            </div>
          </div>
        </div>
        {selected && <HiCheck />}
      </div>
    </div>
  );
}
